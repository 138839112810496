.buttons-container {
  margin: 20px;
}

.buttons-container h4 {
  font-size: 12px;
  margin-top: 20px;
}

/* Button Size Variations */
.ocl_btn_primary{
  border: 1px solid var(--themeColor);
    border-radius: 22px;
    color: white;
    background-color: var(--themeColor);
    font-weight: 400;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: 0.3s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    width: auto;
    user-select: none;
    text-align: center;
    transform-origin: top right;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    height: 35px;
    padding: 10px 0px;

}
.ocl_btn_primary::after {
  background-color: white;
    border-radius: 22px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(0deg);
    transform-origin: top;
    transition: 0.3s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  .ocl_btn_primary:hover::after{
    transform: translate(0, 0);

  }
  .ocl_btn_primary:hover{
    border: 1px solid transparent;
    color: var(--themeColor);
  }
  .ocl_btn_primary.disabled {
    pointer-events: none;
  }





  .ocl_btn_secondary{
    border: 1px solid var(--themeColor);
    border-radius: 22px;
    color: var(--themeColor);
    background-color: white;
    font-weight: 400;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: 0.3s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    width: auto;
    user-select: none;
    text-align: center;
    font-size: 15px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
  
  }
  .ocl_btn_secondary::after {
    background-color: var(--themeColor);
    border-radius: 22px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(0deg);
    transform-origin: top;
    transition: 0.3s transform ease-out;
    will-change: transform;
    z-index: -1;
    }
    .ocl_btn_secondary:hover::after{
      transform: translate(0, 0);
  
    }
    .ocl_btn_secondary:hover{
      border: 1px solid transparent;
    color: white;
    }
    .ocl_btn_secondary.disabled {
      pointer-events: none;
    }
  

  




    
  
  .ocl_btn_tertiary {
    border: 1px solid #D34949;
    border-radius: 22px;
    color: #D34949;
    background-color: white;
    font-weight: 500;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: 0.3s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    width: auto;
    user-select: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    height: 35px;
    padding: 10px 0px;
  }



  
  .ocl_btn_tertiary::after {
    background-color: #D34949;
    border-radius: 22px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(0deg);
    transform-origin: top;
    transition: 0.3s transform ease-out;
    will-change: transform;
    z-index: -1;
    }
    .ocl_btn_tertiary:hover::after{
      transform: translate(0, 0);
  
    }
    .ocl_btn_tertiary:hover{
      border: 1px solid transparent;
    color: white;
    }
    .ocl_btn_tertiary.disabled {
      pointer-events: none;
    }
  


  
.ocl_btn_primary.btn_small,.ocl_btn_secondary.btn_small ,.ocl_btn_tertiary.btn_small {
    padding: 6px 12px;
    font-size: 12px;
  }
  
  .ocl_btn_primary.btn_medium,.ocl_btn_secondary.btn_medium ,.ocl_btn_tertiary.btn_medium {
    padding: 10px 16px;
    font-size: 14px;
  }
  
  .ocl_btn_primary.btn_large,.ocl_btn_secondary.btn_large ,.ocl_btn_tertiary.btn_large  {
    padding: 14px 20px;
    font-size: 16px;
  }
  
  /* Button Style Variations */

  .ocl_btn_primary.btn_auto_fit ,.ocl_btn_secondary.btn_auto_fit  ,.ocl_btn_tertiary.btn_auto_fit  {
    width: auto;
  }
  
  .ocl_btn_primary.btn_fixed  , .ocl_btn_secondary.btn_fixed ,.ocl_btn_tertiary.btn_fixed  {
    width: 200px;
  }
  
  .ocl_btn_primary.btn_full_width  , .ocl_btn_secondary.btn_full_width  , .ocl_btn_tertiary.btn_full_width {
    width: 100%;
  }
  

/* Button Size Variations */
.ocl_btn_primary{
  border: 1px solid var(--themeColor);
    border-radius: 22px;
    color: white;
    background-color: var(--themeColor);
    font-weight: 400;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: 0.3s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    width: auto;
    user-select: none;
    text-align: center;
    transform-origin: top right;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    height: 35px;
    padding: 10px 0px;

}
.ocl_btn_primary::after {
  background-color: white;
    border-radius: 22px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(0deg);
    transform-origin: top;
    transition: 0.3s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  .ocl_btn_primary:hover::after{
    transform: translate(0, 0);

  }
  .ocl_btn_primary:hover{
    border: 1px solid transparent;
    color: var(--themeColor);
  }
  .ocl_btn_primary.disabled {
    pointer-events: none;
  }





  .ocl_btn_secondary{
    border: 1px solid var(--themeColor);
    border-radius: 22px;
    color: var(--themeColor);
    background-color: white;
    font-weight: 400;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: 0.3s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    width: auto;
    user-select: none;
    text-align: center;
    font-size: 15px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
  
  }
  .ocl_btn_secondary::after {
    background-color: var(--themeColor);
    border-radius: 22px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(0deg);
    transform-origin: top;
    transition: 0.3s transform ease-out;
    will-change: transform;
    z-index: -1;
    }
    .ocl_btn_secondary:hover::after{
      transform: translate(0, 0);
  
    }
    .ocl_btn_secondary:hover{
      border: 1px solid transparent;
    color: white;
    }
    .ocl_btn_secondary.disabled {
      pointer-events: none;
    }
  

  




    
  
  .ocl_btn_tertiary {
    border: 1px solid #D34949;
    border-radius: 22px;
    color: #D34949;
    background-color: white;
    font-weight: 500;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: 0.3s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    width: auto;
    user-select: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    height: 35px;
    padding: 10px 0px;
  }



  
  .ocl_btn_tertiary::after {
    background-color: #D34949;
    border-radius: 22px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(0deg);
    transform-origin: top;
    transition: 0.3s transform ease-out;
    will-change: transform;
    z-index: -1;
    }
    .ocl_btn_tertiary:hover::after{
      transform: translate(0, 0);
  
    }
    .ocl_btn_tertiary:hover{
      border: 1px solid transparent;
    color: white;
    }
    .ocl_btn_tertiary.disabled {
      pointer-events: none;
    }
  


  
.ocl_btn_primary.btn_small,.ocl_btn_secondary.btn_small ,.ocl_btn_tertiary.btn_small {
    padding: 6px 12px;
    font-size: 12px;
  }
  
  .ocl_btn_primary.btn_medium,.ocl_btn_secondary.btn_medium ,.ocl_btn_tertiary.btn_medium {
    padding: 10px 16px;
    font-size: 14px;
  }
  
  .ocl_btn_primary.btn_large,.ocl_btn_secondary.btn_large ,.ocl_btn_tertiary.btn_large  {
    padding: 14px 20px;
    font-size: 16px;
  }
  
  /* Button Style Variations */

  .ocl_btn_primary.btn_auto_fit ,.ocl_btn_secondary.btn_auto_fit  ,.ocl_btn_tertiary.btn_auto_fit  {
    width: auto;
  }
  
  .ocl_btn_primary.btn_fixed  , .ocl_btn_secondary.btn_fixed ,.ocl_btn_tertiary.btn_fixed  {
    width: 200px;
  }
  
  .ocl_btn_primary.btn_full_width  , .ocl_btn_secondary.btn_full_width  , .ocl_btn_tertiary.btn_full_width {
    width: 100%;
  }
  

