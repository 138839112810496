.accordion-container-ui-display {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

.accordion-container-ui-display h3{
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 20px;
  }

.accordion-container-ui-display h4 {
  font-size: 12px;
}














/* ===================== General Accordion Styles ==================== */


  
  /* Accordion Box Styles */
  .ocl_accordion_box {
    border: 1px solid #ccc;
    border: 1px solid #94d8d1;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .ocl_accordion_box:hover {
    box-shadow: rgba(60, 64, 67, 0.15) 0px 3.63251px 7.26501px, rgba(60, 64, 67, 0.3) 0px 0.908127px 2.72438px, rgba(128, 134, 139, 0.06) 0px 5.44876px 9.98939px, rgba(128, 134, 139, 0.09) 0px -2.72438px 9.98939px;
  }
  
  /* Accordion Header */
  .ocl_accordion_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 20px;
    background-color: aliceblue;
    border-bottom: 1px solid #94d8d1;
    cursor: pointer;
    font-weight: 600;
  }
  
  
  .ocl_accordion_header span {
    font-size: 18px;
    font-weight: bold;
  }
  
  /* Accordion Content */
  .ocl_accordion_content {
    padding: 13px 20px;
    background-color: #fff;
    font-size: 14px;
  }
  
  /* Small Accordion */
  .ocl_accordion_box.accordion_small .ocl_accordion_header {
    font-size: 12px;
    padding: 13px 20px;
  }
  
  .ocl_accordion_box.accordion_small .ocl_accordion_content {
    font-size: 11px;
  }
  
  /* Medium Accordion */
  .ocl_accordion_box.accordion_medium .ocl_accordion_header {
    font-size: 16px;
    padding: 13px 20px;
  }
  
  .ocl_accordion_box.accordion_medium .ocl_accordion_content {
    font-size: 14px;
  }
  
  /* Large Accordion */
  .ocl_accordion_box.accordion_large .ocl_accordion_header {
    font-size: 18px;
    padding: 13px 20px;
  }
  
  .ocl_accordion_box.accordion_large .ocl_accordion_content {
    font-size: 16px;
  }
  