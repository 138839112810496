.inpBox {
  position: relative;
  width: 100%;
  max-width: 400px;
  border-radius: 4px;
  margin: 20px 0;
}

.inpBox input,
.inpBox textarea,
.inpBox select {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 12px;
  background: #fff;
  color: #333;
  transition: border-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.inpBox input:focus,
.inpBox textarea:focus,
.inpBox select:focus {
  border-color: #0077FF;
  background: #f9faff;
  box-shadow: 0 0 0 2px rgba(0, 119, 255, 0.2);
  outline: none;
}

.inpBox .label {
  position: absolute;
  top: 50%;
  left: 16px;
  font-size: 13px;
  color: #7d7b7b;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  pointer-events: none;
  background: #fff;
  padding: 0 4px;
  z-index: 1;
}

.inpBox .error_message {
  color: red;
  position: absolute;
  font-size: 11px;
  padding-left: 20px;
  margin-top: 2px;
  margin-bottom: 0px;
}

.inpBox input:focus~span,
.inpBox input:valid~span,
.inpBox textarea:focus~span,
.inpBox textarea:valid~span,
.inpBox select:focus~span,
.inpBox select:valid~span {
  top: -0px;
  left: 12px;
  font-size: 12px;
  color: #0077FF;
  font-weight: 500;
}

.inpBox .focus-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: -1;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.inpBox input:focus ~ .focus-bg,
.inpBox select:focus ~ .focus-bg 
.inpBox textarea:focus ~ .focus-bg 
{
  transform: scaleX(1.2);
}

.inpBox input::placeholder,
.inpBox textarea::placeholder,
.inpBox select::-ms-value {
  color: transparent;
}

.checkboxWrapper input {
  border: none;
  outline: none;
  box-shadow: none;
}
.checkboxWrapper input:valid~span {
  top: 10px;
  left: 0px;
  font-size: 13px;
}
.checkboxWrapper input:focus {
  border: none;
  box-shadow: none;
}



/* ======================================= common Settings Page ========================================= */
.common_setting_page{
  padding: 20px;
}


.common_setting_page header{
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.common_setting_page header > div {
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.common_setting_page header > div > button{
  width: 150px;
  font-size: 13px;
  margin-left: 10px;
}
.common_setting_page header > p{
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
}

.common_setting_container{
  margin: 20px 0px;
  max-height: calc(100vh - 175px);
  overflow: auto;
  padding-bottom: 20px;
  margin-bottom: 0;
}


/* ========================================= Common table css================================  */

.common_setting_table {
  width: 100%;
  border-collapse: collapse;
}
.common_setting_table tbody tr td{
  text-align: left;
  padding: 8px 14px;
  min-width: 200px;
  font-size: 11px;
  color: black;
  z-index: 1;
  border: none;
}

.common_setting_table tbody tr:first-child{
  background-color: #ecf8fd;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 100;
  color: black;
  box-shadow: 1px 1px 7px #c6c6c6;
}
.common_setting_table tbody tr:first-child td{
  color: black;
  font-weight: 600;
  text-align: left;
}
.common_setting_table tbody tr:first-child td:first-child{
  background-color: #ecf8fd;
  font-weight: 600;
}
.common_setting_table tbody tr:nth-child(even) td:first-child {
  background-color: #ffffff;
}


.common_setting_table tbody tr:nth-child(even){
  background-color: #ffffff;
}


.common_setting_table tbody tr td:first-child{
  position: sticky;
  left: 0;
  z-index: 50;
  background-color: #ecf8fd;
  font-weight: 500;
  padding-left: 18px;
  text-align: left;
}
.common_setting_table tbody tr:nth-child(even) {
  background-color: #ffffff;
}
.common_setting_table tbody tr:nth-child(odd) {
  background-color: #ecf8fd;
}
.common_setting_table tbody tr:first-child {
  background-color: #ecf8fd;
}


.common_setting_table tbody tr:first-child{
  background-color: #ecf8fd;
  border-top: 1px solid #e4e4e4;
}

/* Make both rows of thead sticky */
.common_setting_table thead tr:first-child th,
.common_setting_table thead tr:nth-child(2) th {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #ecf8fd;
  box-shadow: 1px 1px 7px #c6c6c6;
}

.common_setting_table thead tr:nth-child(2) th {
  top: 40px; /* Adjust this according to the height of the first row */
}

.common_setting_table tbody .operations >i{
  margin-right: 15px;
  cursor: pointer;
  font-size: 12px;
  transition: transform 0.3s ease;
  display: inline-block;
}
.common_setting_table tbody .operations >i:hover{
  transform: scale(1.2);
  color: #333;
}
/* =========================================== common popup css ====================================  */

.tt_settings_popup {
  background-color: #fff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 80%;
  margin: auto;
  text-align: center;
  margin-bottom: 35px;
  margin-top: 50px;
  overflow: hidden;
} 
.tt_settings_popup >  h6{
  font-size: 16px;
  font-weight: 600;
}
.tt_settings_popup .tt_settings_popup_box{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
} 

.tt_settings_popup > section{
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
}
.tt_settings_popup > section > button{
  width: 150px;
  font-size: 14px;
}

/* ========================================== Common page for track and trace ====================================== */

.common_page_track_trace{
  /* padding: 20px; */
  min-height: calc(100vh - 80px);
  background-color: #f9f9f9;
}
.common_page_track_trace .selection_to_display .collapse_page{
  height: 50px; /* Collapsed height */
  overflow: hidden;
  transition: height 0.3s ease;
  background-color: #f8f8f8; /* Optional, you can customize this */
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .common_page_track_trace .page_to_show{
  
} */

.common_page_track_trace .selection_to_display {
  transition: height 0.3s ease;
}


 /* ============================= top bar heading css  ==============================*/

 /* .bar_for_headings {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 4px 15px;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 10;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  padding-right: 100px;
}

 .bar_for_headings > div{
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  margin-right: 15px;
} */
.bar_for_headings {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  position: fixed;
  left: 200px;
  right: 0;
  background: #fff;
  z-index: 1000;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25); */
  box-shadow: rgba(60, 64, 67, 0.15) 0px 3.63251px 7.26501px, rgba(60, 64, 67, 0.3) 0px 0.908127px 2.72438px, rgba(128, 134, 139, 0.06) 0px 5.44876px 9.98939px, rgba(128, 134, 139, 0.09) 0px -2.72438px 9.98939px;
  padding-right: 100px;
  height: 40px;
  padding-top: 0px;
  z-index: 1001;
  transition: 0.3s;
  overflow: hidden;
}
.bar_for_headings.open{
  left: 75px;
}
.bar_for_headings.fullscreen{
  left: 0px;
}

 .bar_for_headings > div{
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  margin-right: 15px;
 
}


.bar_for_headings .collapsed_bar_box {
  height: 35px;
  width: 100px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: row;
  right: -6px;
  position: absolute;
}

.bar_for_headings .collapsed_bar_box i{
  height: 30px;
  width: 30px;
  background-color: #dcdcdc;
  color: #424242;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  cursor: pointer;
  font-size: 16px;
  margin-left: 5px;
  border-radius: 16%;
  transition: 0.1s;
}

.bar_for_headings .collapsed_bar_box i:hover {
  color: black;
  transform: scale(1.1);
}
.bar_for_headings .collapsed_bar_box i:active {
  transform: scale(0.9);
} 


.go_back_to_page_btn {
  position: absolute;
  top: 30px;
  right: 0px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
}

.go_back_to_page_btn:hover {
  background-color: #65C9FF;
}

