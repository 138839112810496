.chart_page_to_show{
  background-color: white;
  position: relative;
}

.chart_page_to_show .chart_header{
  width: 100%;
  /* border: 1px solid black; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-top: 11px;
  height: 21px;
}
.chart_page_to_show .chart_header .save{
  right: 175px;
  z-index: 14;
  font-weight: 500;
  font-size: 14px;
  animation: none;
}
.chart_page_to_show .chart_sorting{
  text-align: center;
  color: blue;
  background: transparent;
  outline: none;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  background-color: #f1f7fd;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #1a00ff;
  border-radius: 3px;
  transition: 0.3s;
  margin-left: 10px;
}
.chart_page_to_show .chart_sorting:hover{
  color: black;
  border-color: black;
}

.chart_page_to_show .full_screen_icon{
  cursor: pointer;
  padding-left: 10px;
}

.chart_page_to_show .full_screen_icon:hover{
  color: #6b6a6a;
}

.chart_page {
    position: relative;
    padding-top: 1px;
    height: fit-content;
    min-height: calc(100vh - 113px);
    transition: 0.3s;
}

.chart_page.open{
    margin-right: 280px;
    width: calc(100% - 280px);
}
.chart_page.fullscreen{
    min-height: calc(100vh - 0px);
}
.chart_page .chart_heading {
    margin: 10px 0px;
    text-align: center;
    font-size: 14px;
}

.chart_page .chart_container {
    position: relative;
    width: 100%;
    height: calc(100vh - 150px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 10px;
    overflow: hidden;
}
.chart_page .chart_container.fullscreen {
    height: calc(100vh - 75px);
}



.y-axis-legend {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    left: 25px;
    bottom: 0;
    font-size: 12px;
    font-weight: 700;
}
  
.y-axis-legend span {
    writing-mode: vertical-rl; 
    transform: rotate(180deg); 
    margin: 5px 0; 
}

.x-axis-legend{
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
}
.x-axis-legend >span{
    margin-right: 5px 0;
}

.chart_container_bubble {
    width: 100%;
    height: 741px;
    margin-left: -70px;
}
.chart_container_bubble.open{
    width: calc(100% - 280px);
    height: 741px;
}





.kyccard-heading {
    background-color: #565656;
}

.custom-tooltip {
    z-index: 9999 !important; /* Increase the z-index */
  }
.text-v-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.kycCard {
    border-radius: 10px;
    padding: 10px;
    box-shadow: -3px 0 3rem rgba(0,0,0,0.1);
}

.i-center {
    display: flex;
    justify-content: center;
}

.main-heading {
    font-family: var(--fontFamily);
    margin: 30px 0 !important;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    letter-spacing: -0.015em;
}

.sub-heading {
    font-size: 24px;
    font-family: var(--fontFamily);
    font-weight: 500;
    margin-bottom: 24px;
}

.sub-body {
    font-size: 14px;
    font-family: var(--fontFamily);
  
}

.hr-line-devider{
    border-top: 2px solid rgba(0,0,0,0.18);
    background: none;
    margin: 30px 0;
    opacity: 1;
}

.sub-title {
    font-family: var(--fontFamily);
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 50px;
    text-align: center;
    letter-spacing: -0.015em;
    color: rgba(0, 0, 0, 0.8);
}

/* ========================= Common css ============ */
.common_bg{
    background: #FFFFFF;
    box-shadow: 0px 2px 22px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}
.main_heading{
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    color: var(--fontColor);
    position: relative;
    width: fit-content;
}
.main_heading::after{
    content: '';
    position: absolute;
    top: 120%;
    left: -20%;
    width: 150%;
    height: 1px;
    background-color: #DFDFDF;
}


.input_tag{
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #A0A6A9;
    position: relative;
    text-align: start;
    margin-bottom: 10px;
}

.inp_box{
    position: relative;
    width: 100%;
    height: 44px;
    margin-bottom: 30px;
}
.inp_box::before{
    content: '';
    position: absolute;
    top: 1px;
    left: 0.5px;
    width: 44px;
    height: 100%;
    background: #EEEEEE;
    border-radius: 50%;
    z-index: 1;
}
.inp_box .input_{
    box-shadow: 1px 2px 21px rgba(0, 0, 0, 0.16);
    border-radius: 22px;
    outline: none;
    border: none;
    width: 100%;
    color: #565656;
    padding: 10px 15px 10px 60px;
    position: relative;
    border: 1px solid #e0e0e0;
}

.inp_box  i{
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 14px;
}
.inp_box i .input_icon{
    color: #A0A6A9;
}
  
.common_btn {
    border: 1px solid var(--themeColor);
    border-radius: 22px;
    color: white;
    background-color: var(--themeColor);
    font-weight: 400;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: 0.3s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    width: 100%;
    user-select: none;
    text-align: center;
    transform-origin: top right;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 15px;
    height: 35px;
  }
  .common_btn::after {
    background-color: white;
    border-radius: 22px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(0deg);
    transform-origin: top;
    transition: 0.3s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  .common_btn:hover::after {
    transform: translate(0, 0);
  }
  .common_btn:hover {
    border: 1px solid transparent;
    color: var(--themeColor);
  }
  
  .common_btn.disabled {
    pointer-events: none;
  }


.common_btn2 {
    border: 1px solid var(--themeColor);
    border-radius: 22px;
    color: var(--themeColor);
    background-color: white;
    font-weight: 400;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: 0.3s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    width: 100%;
    user-select: none;
    text-align: center;

    font-size: 15px;
    height: 35px;
  }
  .common_btn2::after {
    background-color: var(--themeColor);
    border-radius: 22px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(0deg);
    transform-origin: top;
    transition: 0.3s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  .common_btn2:hover::after {
    transform: translate(0, 0);
  }
  .common_btn2:hover {
    border: 1px solid transparent;
    color: white;
  }
    
  .common_btn3{
    border: 1px solid #D34949;
    border-radius: 22px;
    color: #D34949;
    background-color: white;
    font-weight: 500;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: 0.3s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    width: 100%;
    user-select: none;
    text-align: center;
    
    font-size: 15px;
    height: 35px;
  }
  .common_btn3::after {
    background-color:#D34949;
    border-radius: 22px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(0deg);
    transform-origin: top;
    transition: 0.3s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  .common_btn3:hover::after {
    transform: translate(0, 0);
  }
  .common_btn3:hover {
    border: 1px solid transparent;
    color: white;
  }
  
  


  

@media screen and (max-width: 1100px) {

}
@media screen and (max-width: 960px) {

}
@media screen and (max-width: 600px) {
    .main_heading {
        font-size: 18px;
    }
    .inp_box .input_ {
        padding: 10px 15px 10px 50px;
        font-size: 14px;
    }
    .inp_box::before {
        width: 42px;
        height: 93%;
    }
}
/* Overlay to blur the background */
.blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
    backdrop-filter: blur(5px); /* Apply the blur effect */
    z-index: 9998; /* Ensure it is behind the popup */
  }
  
  /* Popup styling */
  .expiration-warning-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    background-color: #004080; /* Dark blue background */
    color: #ffffff; /* White text */
    border: 2px solid #003366; /* Slightly darker blue border */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 9999; /* Ensures it appears above the overlay */
    width: 80%;
    max-width: 400px;
    text-align: center;
  }
  
  .expiration-warning-popup p {
    margin: 0;
    font-size: 16px;
    line-height: 1.4;
  }
  
  .expiration-warning-popup button {
    background-color: #0066cc; /* Lighter blue for the button */
    border: none;
    color: white;
    padding: 10px 20px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .expiration-warning-popup button:hover {
    background-color: #004d99; /* Darker blue on hover */
  }
  