.select_warning{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
}
 .select_warning>img{
    width: 40%;
    height: 60%;
    opacity: 0.6;
}
.select_warning>h6{
    font-size: 18px;
    font-weight: 500;
}
.select_warning>a{
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    animation: moving_create_text 1.8s ease-in-out infinite;
    transition-delay: 0.2s;
    border-bottom: 0.5px solid blue;
    margin-bottom: -5px;
}

@keyframes moving_create_text{
    0%,100%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.2);
    }
}
