.my_error_box {
    width: 80%;
    height: 42px;
    text-align: center;
    font-size: 15px;
    background-color: red;
    border-radius: 5px;
    padding: 10px 5px;
    font-weight: 400;
    margin: 20px auto;
    color: white;
}
.wrong_img{
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.wrong_img img{
    width: 300px;
}