:root {
    --graybg: #f5f5f5;
    --cardBg: white;
    --themeColor: rgb(38,178,253);
    
    /* --themeColor: #2196f3; */
    --fontColor: var(--themeColor);
    --bs-info: var(--themeColor) !important;
    --fontFamily: "Montserrat";
    --arrow-bg: rgb(196 196 196);
    --arrow-icon: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg);
    --file-attachement: url(https://www.svgrepo.com/show/451688/mail-attachment.svg);
    --arrow-down:url(https://www.svgrepo.com/show/80156/down-arrow.svg);
    --option-bg: white;
    --select-bg: rgba(255, 255, 255, 0.2);
}

*{
    font-family: var(--fontFamily);
}
a {
    /* color: var(--bs-link-color); */
    text-decoration: underline;
    text-decoration: none;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat/Montserrat-SemiBold.eot');
    src: url('/src/assets/fonts/Montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat/Montserrat-SemiBold.woff') format('woff'),
        url('/src/assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat/Montserrat-Regular.eot');
    src: url('/src/assets/fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat/Montserrat-Regular.woff') format('woff'),
        url('/src/assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat/Montserrat-Italic.eot');
    src: url('/src/assets/fonts/Montserrat/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat/Montserrat-Italic.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat/Montserrat-Italic.woff') format('woff'),
        url('/src/assets/fonts/Montserrat/Montserrat-Italic.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat/Montserrat-Italic.svg#Montserrat-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat/Montserrat-ExtraLightItalic.eot');
    src: url('/src/assets/fonts/Montserrat/Montserrat-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat/Montserrat-ExtraLightItalic.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat/Montserrat-ExtraLightItalic.woff') format('woff'),
        url('/src/assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat/Montserrat-ExtraLightItalic.svg#Montserrat-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat/Montserrat-BoldItalic.eot');
    src: url('/src/assets/fonts/Montserrat/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat/Montserrat-BoldItalic.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat/Montserrat-BoldItalic.woff') format('woff'),
        url('/src/assets/fonts/Montserrat/Montserrat-BoldItalic.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat/Montserrat-BoldItalic.svg#Montserrat-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat/Montserrat-Bold.eot');
    src: url('/src/assets/fonts/Montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat/Montserrat-Bold.woff') format('woff'),
        url('/src/assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat/Montserrat-BlackItalic.eot');
    src: url('/src/assets/fonts/Montserrat/Montserrat-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat/Montserrat-BlackItalic.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat/Montserrat-BlackItalic.woff') format('woff'),
        url('/src/assets/fonts/Montserrat/Montserrat-BlackItalic.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat/Montserrat-BlackItalic.svg#Montserrat-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat/Montserrat-ExtraBold.eot');
    src: url('/src/assets/fonts/Montserrat/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat/Montserrat-ExtraBold.woff') format('woff'),
        url('/src/assets/fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat/Montserrat-SemiBoldItalic.eot');
    src: url('/src/assets/fonts/Montserrat/Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat/Montserrat-SemiBoldItalic.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat/Montserrat-SemiBoldItalic.woff') format('woff'),
        url('/src/assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat/Montserrat-SemiBoldItalic.svg#Montserrat-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat/Montserrat-ExtraLight.eot');
    src: url('/src/assets/fonts/Montserrat/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat/Montserrat-ExtraLight.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat/Montserrat-ExtraLight.woff') format('woff'),
        url('/src/assets/fonts/Montserrat/Montserrat-ExtraLight.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat/Montserrat-ExtraLight.svg#Montserrat-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat/Montserrat-Black.eot');
    src: url('/src/assets/fonts/Montserrat/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat/Montserrat-Black.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat/Montserrat-Black.woff') format('woff'),
        url('/src/assets/fonts/Montserrat/Montserrat-Black.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat/Montserrat-Black.svg#Montserrat-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.eot');
    src: url('/src/assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.woff') format('woff'),
        url('/src/assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.svg#Montserrat-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat/Montserrat-MediumItalic.eot');
    src: url('/src/assets/fonts/Montserrat/Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat/Montserrat-MediumItalic.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat/Montserrat-MediumItalic.woff') format('woff'),
        url('/src/assets/fonts/Montserrat/Montserrat-MediumItalic.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat/Montserrat-MediumItalic.svg#Montserrat-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat/Montserrat-Medium.eot');
    src: url('/src/assets/fonts/Montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat/Montserrat-Medium.woff') format('woff'),
        url('/src/assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}



body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* zoom: 0.8; */
    
}
img{
    max-width: 100%;
}

table{
    user-select: none;
}



